import { Component } from "react";

const accountId = 1;

export default function App() {
  return (
    <div>
    account id: {accountId}

      <Tables />
      <SQLConsole />

    SQL logs:
      <div id="logs" />
    </div>
  );
}

class SQLConsole extends Component {
  constructor() {
    super();
    this.state = {
      result: null,
    };

    this.send = this.send.bind(this)
  }

  async send() {
    const query = document.getElementById("query").value;
    const result = await sql(query);
    this.setState({ result })
  }

  render() {
    return (
      <div className="shadow-xl rounded-2xl my-12 mx-32 bg-blue-100 p-3">
        <div className="card-body">
          <h2 className="card-title">SQL console</h2>
        </div>

        <textarea
          className="textarea"
          placeholder="select * from TABLE"
          className="w-full"
      id="query"
        ></textarea>
      <button className="inline-block px-12 py-3 text-sm font-medium text-indigo-600 border border-indigo-600 rounded hover:bg-indigo-600 hover:text-white active:bg-indigo-500 focus:outline-none focus:ring" onClick={this.send}>send</button>

      <div>
      Result:
      <code>
        <pre>
        {JSON.stringify(this.state.result, null, 4)}
        </pre>
      </code>
      </div>
      </div>
    );
  }
}

class Tables extends Component {
  constructor() {
    super();
    this.state = {
      tables: [],
      selectedTable: null,
    };
  }

  async componentDidMount() {
    await this.fetchTables();
  }

  async fetchTables() {
    const list = await sql("pragma table_list");
    const tables = list.map((entry) => ({ name: entry.name, schema: null }));
    this.setState({ tables });
  }

  async selectTable(name) {
    const schema = await sql(`pragma table_info("${name}")`);
    this.setState({ selectedTable: name, schema });
  }

  render() {
    return (
      <>
        <div className="shadow-xl rounded-2xl my-12 mx-32 bg-blue-100 p-3">
          <h1 className="text-xl font-bold ">Tables</h1>

          <table className="table mt-2 text-sm  block p-6 bg-white sm:p-8 rounded-xl w-full">
            <thead>
              <tr>
                <th>Table name</th>
                <th>actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.tables.map((table) => {
                return (
                  <tr key={table.name}>
                    <td>{table.name}</td>
                    <td>
                      <button className="inline-block px-12 py-3 text-sm font-medium text-indigo-600 border border-indigo-600 rounded hover:bg-indigo-600 hover:text-white active:bg-indigo-500 focus:outline-none focus:ring" onClick={() => this.selectTable(table.name)}>
                        view
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {this.state.selectedTable ? (
        <div className="shadow-xl rounded-2xl my-12 mx-32 bg-blue-100 p-3">
          <h2 className="text-xl font-bold ">Table: {this.state.selectedTable}</h2>

          <table className="table mt-2 text-sm  block p-6 bg-white sm:p-8 rounded-xl w-full">
                <thead>
                  <tr>
                    <th>Column name</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.schema.map((col) => {
                    return (
                      <tr key={col.name}>
                        <td>{col.name}</td>
                        <td>{col.type}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
        ) : null}
      </>
    );
  }
}

async function sql(query) {
  const res = await fetch("https://sql.that-test.site/" + accountId + "/query", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ sql: query + ";" }),
  });
  document.getElementById("logs").innerHTML += `"${query}" returned ${res.status}<br />`
  return res.json();
}

// docs https://www.sqlite.org/pragma.html
